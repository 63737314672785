$(document).ready(function() {

	$('a[href*=#]:not([href=#])').click(function() {
		if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top
				}, 1000);
				return false;
			}
		}
	});

	if($("#address-checkbox").is(':checked')){
		$("#billing-info").slideUp()
	} else {
		$("#billing-info").slideDown();
	}

	// Address Checkbox
	$("#address-checkbox").click(function(){
		if($("#address-checkbox").is(':checked')){
			$("#billing-info").slideUp()
		} else {
			$("#billing-info").slideDown();
		}
	});

	$("#guarantee-info-btn-1").click(function(e){
		e.preventDefault();
		$("#guarantee-info-1").slideToggle();
	});

	$("#guarantee-info-btn-2").click(function(e){
		e.preventDefault();
		$("#guarantee-info-2").slideToggle();
	});

	// CVV Info Button
	$("#cvv-info-btn").click(function(e){
		e.preventDefault();
		$("#cvv-info-box").fadeIn();
	});
	$("#cvv-close-btn").click(function(t){
		t.preventDefault();
		$("#cvv-info-box").fadeOut();
	});

	// Add to Cart
	$(".add-to-cart").click(function(e){
		e.preventDefault();
		var cartCount = $('.cart-item').length;
		var productId = $(this).attr("data-product-id");
		var productTitle = $(this).attr("data-product-title");
		var productPrice = $(this).attr("data-product-price");
		var productImg = $(this).attr("data-product-img");

		$('html, body').animate({
			scrollTop: $("#checkout").offset().top
		}, 1000);


		if($(".cart-product-" + productId).length > 0){
			$("#product-" + productId).fadeOut('slow', function() {
				var product = $('.item-qty-' + productId);
				product.val(parseInt(product.val()) + 1);
				$("#product-" + productId).fadeIn('slow');
			});

		} else {


			$('<div id="product-' + productId + '" class="row cart-item"><div class="span2 item-img"><img src="' + productImg +'" class="full-img"></div><div class="span5 item-name"><span>' + productTitle + '</span></div><div class="span1 item-qty-label"><span  class="text-center">QTY:</span></div><div class="span1 item-qty"><input name="items[' + cartCount + '][qty]" class="input item-qty-' + productId + ' cart-product-' + productId + '" maxlength="2" value="1"></div><div class="span3 item-price"><span>' + productPrice + '</span></div></div>').hide().appendTo('#cart-items').fadeIn("slow");

			$('<input>').attr({
			    type: 'hidden',
			    class: 'cart-product-' + productId,
			    name: 'items[' + cartCount + '][id]',
			    value: productId
			}).appendTo('#product-' + productId);

			cartCount++;

		}


	});

	//Disable submit button on click
	$('input[type=submit]').click(function() {
	    $(this).attr('disabled', 'disabled');
		$(this).parents('form').submit()
	});

});
